const fragmentShader = `
precision mediump float;
varying vec4 vColor;
void main() {
    float radius = 0.0;
    vec2 cxy = 2.0 * gl_PointCoord - 1.0;
    radius = dot(cxy, cxy);
    
    // Calculate a smooth step on the edge of the circle
    float edge = 1.0 - smoothstep( 0.8, 1.0, radius );
    
    if (radius > 1.0) {
        discard;
    }

    // Multiply the color by the edge to create a border effect
    vec4 finalColor = vColor * edge;

    // Output the final color
    gl_FragColor = finalColor;
}
`;

export default fragmentShader;